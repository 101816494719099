import * as React from "react";
import image from '../../images/survivor.png';
import Project from '../../Layouts/project';

const { Fragment } = React;

// markup
const Survivor = () => {
  return (
    <Project 
      title="Winners at War" 
      images={[image]}
      link="https://winners-at-war.netlify.app/"
      description={<Fragment>Just for fun, visualizing past appearances of the cast of Surivovor's all-winners 40th season. People liked it on <a href='https://www.reddit.com/r/survivor/comments/frab8j/visualizing_waw_cast_members_past_seasonswins/' target='_blank'>Reddit</a>!</Fragment>}
      />)
}

export default Survivor
